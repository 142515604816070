import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import Find from "../components/section/Find"
// image
import Award from "../images/slide-awards-large.jpg"
import Award1 from "../images/inc5000.png"
import Award2 from "../images/logo-coloradobiz-topcompany.png"
import Award3 from "../images/logo-dbj.png"

const CompanyAwards = data => {
  return (
    <Layout title="Recuiting Services">
      <SEO title="Recuiting Services" />
      <div id="ca-page">
        <div className="page-headline pb-0">
          <Container className="pb-100">
            <h1 className="bottom-underline text-center text-blue">
              Company Awards
            </h1>
          </Container>
        </div>

        <div className="award-section py-50" style={{ background: "#176588" }}>
          <Container>
            <Row>
              <Col lg="4">
                <div className="awards-container">
                  <div className="awards-logo text-center">
                    {" "}
                    <img src={Award1} className="img-fluid" alt="awardee" />
                  </div>
                  <div className="awards-content">
                    <h6>Fastest Growing Companies in the US</h6>
                    <p className=" mb-0">INC 5000</p>
                    <p className=" mb-0">2021, 2020, 2019, 2018, 2017</p>
                  </div>
                </div>
              </Col>

              <Col lg="4">
                <div className="awards-container">
                  <div className="awards-logo text-center">
                    {" "}
                    <img src={Award3} className="img-fluid" alt="awardee" />
                  </div>
                  <div className="awards-content">
                    <h6>Top Contract Placement Firms</h6>
                    <p className=" mb-0">Denver Business Journal</p>
                    <p className=" mb-0">
                      2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017
                    </p>
                    <h6>Top Direct Hire Placement Firms</h6>
                    <p className=" mb-0">Denver Business Journal</p>
                    <p className=" mb-0">
                      2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017
                    </p>
                    <h6>Fastest Growing Private Companies</h6>
                    <p className=" mb-0">Denver Business Journal</p>
                    <p className=" mb-0">2020, 2017, 2015</p>
                    {/* <h6>Small Business Award</h6>
                    <p className=" mb-0">Denver Business Journal</p>
                    <p className=" mb-0">2017, 2016</p> */}
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className="awards-container">
                  <div className="awards-logo text-center">
                    {" "}
                    <img src={Award2} className="img-fluid" alt="awardee" />
                  </div>
                  <div className="awards-content">
                    <h6>Top 200 Private Colorado Companies</h6>
                    <p className=" mb-0">ColoradoBiz</p>
                    <p className=" mb-0">2021, 2020, 2018</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* <Container>
            <Row>
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={4}>
                    <img src={Award1} className="img-fluid" alt="awardee" />
                  </Col>
                  <Col md={8} className="align-self-center"></Col>
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={4}>
                    <img src={Award2} className="img-fluid" alt="awardee" />
                  </Col>
                  <Col md={8} className="align-self-center"></Col>
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={4}>
                    <img src={Award3} className="img-fluid" alt="awardee" />
                  </Col>
                  <Col md={8} className="align-self-center"></Col>
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={4}></Col>
                  <Col md={8} className="align-self-center"></Col>
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={4}></Col>
                  <Col md={8} className="align-self-center"></Col>
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={4}></Col>
                  <Col md={8} className="align-self-center"></Col>
                </Row>
              </Col>
            </Row>
          </Container> */}
        </div>
        {/*} <div
          className="page-banner py-100"
          style={{
            background: `url(${Award})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center text-center",
            height: "auto",
          }}
        >
           <Container>
            <Row>
              <Col md={6} className="align-self-center">
                <h2
                  style={{
                    color: "#fbbc04",
                    background: "rgba(14,40,71,0.40)",
                    padding: "20px",
                    borderRadius: "30px",
                  }}
                >
                  FEATURED ON INC 5000 AND THE DENVER BUSINESS JOURNAL
                </h2>
              </Col>
            </Row>
          </Container>
        </div>*/}
        <div>
          <Container>
            <Find />
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default CompanyAwards
